import LinkButton from "../LinkButton/LinkButton";
import "./HowToSurrender.scss";
import logoUrl from "./kh-zh-logo.png";

export default function HowToSurrender() {
  return (
    <div className="HowToSurrender">
      <h3 className="title">Добровольная сдача в плен</h3>
      <div className="content">
        Если вы или ваш близкий пребываете в зоне боевых действий и хотите
        вернуться домой живым, обращайтесь в проект «Хочу жить»
      </div>
      <LinkButton
        variant="mostPopular"
        href="https://hochuzhit.com/"
        className="hochuzhyt-hochuzhytlanding-button"
      >
        Узнать больше
      </LinkButton>
      <div className="logoContainer">
        <a
          href="https://hochuzhit.com/"
          className="hochuzhyt-hochuzhytlanding-logo"
        >
          <img src={logoUrl} alt='Логотип проекта "Хочу жить"' />
        </a>
      </div>
    </div>
  );
}
