import LinkButton from "../LinkButton/LinkButton";
import { SmButtons } from "../SmButtons/SmButtons";
import "./Footer.scss";
import CondensedLogo from "./CondensedLogo/CondensedLogo";

export default function Footer() {
  return (
    <div className="Footer">
      <div className="topPart">
        <CondensedLogo />

        <LinkButton
          variant="underlined"
          className="chatbot footer-hochunaitichatbot-button"
          href="https://t.me/hochunaiti_bot"
        >
          Связаться через чат-бот
        </LinkButton>

        <div className="buttonsContainer">
          <div className="heading">Мы в соц. сетях:</div>
          <SmButtons />
        </div>
      </div>
      <div className="bottomPart">
        <div className="left">
          <a className="hostUrl" href="https://hochunaiti.com">
            hochunaiti.com
          </a>
          <div className="copyright">2024. Все права защищены</div>
        </div>
      </div>
    </div>
  );
}
