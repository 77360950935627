import LinkButton from "../LinkButton/LinkButton";
import "./WhereWeSearch.scss";
import WhereWeSearchTile from "./WhereWeSearchTile/WhereWeSearchTile";

export default function WhereWeSearch() {
  return (
    <div className="WhereWeSearch">
      <h3>Где мы ищем пропавших военных в Украине?</h3>
      <div className="tilesWrapper">
        <div className="tilesContainer">
          <WhereWeSearchTile title="Военнопленные">
            Поиск проводится во всех предусмотренных законодательством Украины
            местах содержания, а именно: в лагерях и участках содержания
            военнопленных, в учреждениях исполнения наказаний и следственных
            изоляторах Государственной уголовно-исполнительной службы Украины
          </WhereWeSearchTile>
          <WhereWeSearchTile title="Погибшие">
            Поиск погибших и пропавших без вести проводится по единой базе
            информации о найденных, идентифицированных, репатриированных телах и
            останках
          </WhereWeSearchTile>
        </div>
        <LinkButton
          href="https://t.me/hochunaiti_bot"
          variant="mostPopular"
          className="gdemyischem-hochunaitichatbot-button"
        >
          Искать
        </LinkButton>
      </div>
    </div>
  );
}
